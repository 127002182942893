<template>
  <b-modal
    visible
    scrollable
    centered
    :title="title"
    size="md"
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <div
      v-if="isLoading"
      class="d-flex justify-content-center w-100"
    >
      <b-spinner variant="success" />
    </div>

    <template v-else>
      <div>
        <b-form-group
          label="Название"
          label-class="required"
          :state="!$v.form.name.$error"
          :invalid-feedback="errorsValidation.name[0]"
        >
          <b-form-textarea
            v-model.trim="$v.form.name.$model"
            placeholder="Введите текст"
            rows="1"
            max-rows="5"
            size="sm"
          />
        </b-form-group>
      </div>
    </template>

    <template #modal-footer>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="onClose"
      >
        Отменить
      </b-button>

      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="onClickSave"
      >
        Сохранить
        <b-spinner
          v-if="isSaving"
          variant="light"
          small
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  showCustomMessage,
  showValidationErrorMessage,
  showValidationErrorCustomMessage,
} from '@/helpers/messages';
import { appealsTemplatesChatService } from '@/services';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'AppealTemplatesEditChatModal',
  components: {
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Создание шаблона',
    },
    templateData: {
      type: Object,
      default: () => ({}),
    },
    appealType: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      isOpen: true,
      isLoading: false,
      isSaving: false,
      form: {
        name: null,
      },
    };
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
      },
    };
  },
  computed: {
    isEdit() {
      return Object.keys(this.templateData).length;
    },
    errorsValidation() {
      const errors = {};

      errors.name = [];
      if (!this.$v.form.name.required) {
        errors.name.push('Поле Название обязательно для заполнения');
      }

      return errors;
    },
  },
  created() {
    if (this.isEdit) {
      const newTemplateData = JSON.parse(JSON.stringify(this.templateData));
      this.form = {
        name: newTemplateData.value,
      };
    }
  },

  methods: {
    onClose() {
      this.$emit('input', false);
    },
    async onClickSave() {
      this.$v.$touch();
      if (this.$v.$error) {
        console.log(this.$v.error);
        showValidationErrorMessage();
        return;
      }
      this.isSaving = true;

      try {
        const { name } = this.form;
        const data = { text: JSON.stringify(name) };

        if (this.isEdit) {
          const { key } = this.templateData;
          await appealsTemplatesChatService.update(key, data);
          showCustomMessage('success', 'Шаблон был обновлен', '');
        } else {
          await appealsTemplatesChatService.create(data);
          showCustomMessage('success', 'Шаблон создан', '');
        }
        this.$store.commit('AppealsTemplates/TOGGLE_FETCH_CHAT_APPEALS_TEMPLATES_TRIGGER');

        this.onClose();
      } catch (e) {
        console.warn(e);
        showValidationErrorCustomMessage('Возможно шаблон с таким названием уже существует');
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
